const plans = [
  {
    name: 'Plan A',
    duration: '1 Year',
    price: 599,
    discount: ''
  },
  {
    name: 'Plan B',
    duration: '5 Years',
    price: 2499,
    discount: 500
  },
  {
    name: 'Plan C',
    duration: '10 Years',
    price: 4999,
    discount: 1000
  }
]

export default plans
