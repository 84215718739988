import MultipleLocations from './multipleLocations'
import SingleLocation from './singleLocation'
import UserSelect from './userSelect'

const GoogleMap = {
  MultipleLocations,
  SingleLocation,
  UserSelect
}

export default GoogleMap
