import Admins from './admins'
import Business from './business'
import Leads from './leads'
import Users from './users'

const Main = {
  Admins,
  Business,
  Leads,
  Users
}

export default Main
