import { Alert, Modal } from 'components'
import s from './styles.module.scss'
import { useState, useRef } from 'react'
import * as api from 'api'
import { useNavigate } from 'react-router-dom'

export default function Main({ userData }) {
  return (
    <div className={s.main}>
      <div className={s.table}>
        <div className={s.tableHeading}>
          <div className={s.srNo}>S.No.</div>
          <div className={s.userName}>User Name</div>
          <div className={s.actions}>Actions</div>
        </div>
        <div className={s.tableBody}>
          {userData.map(({ _id, firstName, lastName, isBlocked }, i) => (
            <TableRow _id={_id} firstName={firstName + ' ' + lastName} isBlocked={isBlocked} index={i} key={i} />
          ))}
        </div>
      </div>
    </div>
  )
}

const TableRow = ({ _id, firstName, isBlocked, index }) => {
  const [userBlockStatus, setUserBlockStatus] = useState(isBlocked)
  const processing = useRef(false)
  const Navigate = useNavigate()

  const BlockHandler = async () => {
    if (processing.current) return
    processing.current = true

    const toggleUserBlockStatus = await api.admin.user.blockUser({ id: _id })

    if (toggleUserBlockStatus.code === 201) {
      setUserBlockStatus(toggleUserBlockStatus.payload.status)
      toggleUserBlockStatus.payload.status
        ? Alert.warn(toggleUserBlockStatus.message)
        : Alert.success(toggleUserBlockStatus.message)
    } else {
      Alert.error(toggleUserBlockStatus.message)
    }
    processing.current = false
  }

  const userHandler = async () => {
    if (processing.current) return
    processing.current = true
    const userAuth = await api.auth.admin.getUserAdmin({ id: _id })
    if (userAuth.code === 201) {
      window.localStorage.setItem('authorization', userAuth.payload.authorization)
      window.localStorage.setItem('userData', JSON.stringify(userAuth.payload.userData))
      Navigate('/userAdmin/editProfile', { replace: true })
    } else {
      Alert.warn(userAuth.error)
    }
    processing.current = false
  }

  return (
    <div className={s.tableRow}>
      <div className={s.srNo}>{index + 1}</div>
      <div className={s.userName} onClick={userHandler}>
        {firstName}
      </div>
      <div className={s.actions}>
        <div
          onClick={async () =>
            Modal.Confirm(
              'Are u sure u want to ' + (userBlockStatus ? 'unblock ' : 'block ') + firstName + '?',
              BlockHandler
            )
          }
          title='Block User'
          className={s.blockUser}
        >
          <span
            className='material-icons-outlined'
            style={{ color: userBlockStatus ? 'var(--c-red)' : 'var(--c-font)' }}
          >
            block
          </span>
        </div>
      </div>
    </div>
  )
}
